import { FC, useEffect, useState } from 'react';
import { Page } from '../../components';
import {
  Alert,
  AlertTitle,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import { getMarkets } from '../../fetch/lookups';
import { IDropdownResponse } from '../../models/util';
import { useSnackbar } from 'notistack';
import { startOfWeek, lastDayOfWeek } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { ReliefSchedulingRepeater } from './relief-scheduling-repeater';
import { RSSMarketRepeaterHandler } from '../../context/rss-market-repeater';

export const ReliefScheduling: FC = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(new Date());

  const isNotPhone = useMediaQuery('only screen and (min-width : 768px)');
  const { enqueueSnackbar } = useSnackbar();

  const isToday = (someDate: Date | null) => {
    if (someDate === null) return;

    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  // Dropdowns
  const [locations, setLocations] = useState<IDropdownResponse[]>();
  const [areLocationsLoading, setAreLocationsLoading] = useState(false);

  const fetchLocations = async () => {
    try {
      setAreLocationsLoading(true);
      const res = await getMarkets({
        excludeMarketsWithoutLocations: true,
      });
      setLocations(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading markets, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreLocationsLoading(false);
    }
  };

  // Schedule
  const [startDate, setStartDate] = useState(startOfWeek(selectedDate, { weekStartsOn: 1 }));
  const [endDate, setEndDate] = useState(lastDayOfWeek(selectedDate, { weekStartsOn: 1 }));
  const [showInactive, setShowInactive] = useState<boolean>(false);

  useEffect(() => {
    fetchLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStartDate(startOfWeek(selectedDate, { weekStartsOn: 1 }));
    setEndDate(lastDayOfWeek(selectedDate, { weekStartsOn: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <Page
      title={'Relief Scheduling'}
      additionalHeaderContent={
        isNotPhone ? (
          <Grid
            alignItems={'center'}
            justifyContent={'flex-end'}
            container
            spacing={1}
            className={classes.schedPaginationControls}
          >
            <Grid
              display={'flex'}
              textAlign={'left'}
              alignItems={'flex-start'}
              flexDirection={'column'}
              item
              xs={5}
            >
              <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                labelPlacement="top"
                control={
                  <Switch
                    checked={showInactive}
                    color={'secondary'}
                    onChange={_ => {
                      setShowInactive(prev => !prev);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Show Inactive Requests?"
              />
            </Grid>
            <Grid item xs={3} className={'this-week-btn-wrap'}>
              <Button
                onClick={() => {
                  setSelectedDate(new Date());
                  setDate(new Date());
                }}
                disabled={isToday(date)}
              >
                This Week
              </Button>
            </Grid>
            <Grid container item alignItems={'center'} xs={3}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    const prevWeek = new Date(date);
                    prevWeek.setDate(prevWeek.getDate() - 7);
                    setSelectedDate(prevWeek);
                    setDate(prevWeek);
                  }}
                  title={'Previous Week'}
                  className={classes.weekPaginationBtns}
                >
                  <ArrowBackIos style={{ fontSize: '12px' }} />
                </IconButton>
              </Grid>

              <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                  <DesktopDatePicker
                    toolbarPlaceholder={'Date'}
                    inputFormat="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={val => {
                      setDate(val as Date);
                    }}
                    onAccept={val => {
                      setSelectedDate(val as Date);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        onBlur={() => {
                          const earliestPossibleDate = new Date('January 1, 1900');
                          if (date < earliestPossibleDate) {
                            setSelectedDate(earliestPossibleDate);
                            setDate(earliestPossibleDate);
                          } else {
                            setSelectedDate(date);
                            setDate(date);
                          }
                        }}
                        size="small"
                        variant="standard"
                        {...params}
                      ></TextField>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    const nextWeek = new Date(date);
                    nextWeek.setDate(nextWeek.getDate() + 7);
                    setSelectedDate(nextWeek);
                    setDate(nextWeek);
                  }}
                  title={'Next Week'}
                  className={classes.weekPaginationBtns}
                >
                  <ArrowForwardIos style={{ fontSize: '12px' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : undefined
      }
    >
      {isNotPhone ? (
        <RSSMarketRepeaterHandler>
          <ReliefSchedulingRepeater
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            locations={locations}
            setLocations={setLocations}
            areLocationsLoading={areLocationsLoading}
            setAreLocationsLoading={setAreLocationsLoading}
            startDate={startDate}
            endDate={endDate}
            showInactiveRequests={showInactive}
          />
        </RSSMarketRepeaterHandler>
      ) : (
        <Alert severity="warning">
          <AlertTitle>Scheduling not available at this screen resolution</AlertTitle>
        </Alert>
      )}
    </Page>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  saveButton: {
    marginTop: theme.spacing(1),
  },
  schedPaginationControls: {
    '& .this-week-btn-wrap': {
      paddingTop: theme.spacing(1.75),
    },
  },
  weekPaginationBtns: {
    border: 'none',
    background: 'none',
    color: theme.palette.common.black,
    padding: 0,
    borderRadius: 0,
    minWidth: theme.spacing(4),

    '&:hover': {
      background: 'none',
    },
  },
}));
