import { appInsights } from '../appInsightsConfig';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

const config = require('../buildSettings.json');
let localEnv = 'Local';

export const logInfo = (details: any, message: string) => {
  let obj = {
    message: message,
    severityLevel: SeverityLevel.Information,
  };

  if (config.APPLICATIONINSIGHTS_ENVIRONMENT === localEnv) {
    console.log(details);
    return;
  }

  if (appInsights) {
    appInsights.trackTrace(obj);
  }
};

export const logWarning = (details: any, message: string) => {
  let obj = {
    message: message,
    severityLevel: SeverityLevel.Warning,
  };

  if (config.APPLICATIONINSIGHTS_ENVIRONMENT === localEnv) {
    console.log(details);
    return;
  }

  if (appInsights) {
    appInsights.trackTrace(obj);
  }
};

export const logError = (error: any, message: string) => {
  let obj = {
    message: error,
    severityLevel: SeverityLevel.Error,
  };

  if (config.APPLICATIONINSIGHTS_ENVIRONMENT === localEnv) {
    console.log(error);
    return;
  }

  if (appInsights) {
    appInsights.trackException(obj);
  }
};
