import { FC, useContext, useEffect, useState } from 'react';
import { Page, Map } from '../../components';
import { Box, Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { getAvailableDVMShifts, getMapLocations } from '../../fetch';
import { IAvailableShift, IMapLocation } from '../../models/maps';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

import { IDateRange } from '../../models/date';
import { DateRangePicker } from '../../components/input/date-range';
import { ShiftOverlay } from './shift-overlay';
import { RequestShifts } from './request-shifts';
import { EventAvailable, Search } from '@mui/icons-material';
import { format } from 'date-fns';
import { useCurrentHeight } from '../../helpers/resize';
import { UserContext } from '../../context';

export const Home: FC = () => {
  const [mapLocations, setMapLocations] = useState<IMapLocation[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: null,
    endDate: null,
    key: 'selection',
    inputValue: '',
  });

  const [zipCode, setZipCode] = useState('');

  const [infoWindow, setInfoWindow] = useState<number | null>();
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const [isOverlayCovering, setIsOverlayCovering] = useState(false);
  const [availableShifts, setAvailableShifts] = useState<IAvailableShift[]>([]);
  const [isLoadingAvailableShifts, setIsLoadingAvailableShifts] = useState(false);
  const { isDVM, isDVMDeactivated } = useContext(UserContext);

  const defaultStartDate = new Date();
  defaultStartDate.setDate(new Date().getDate());

  const defaultEndDate = new Date();
  defaultEndDate.setDate(new Date().getDate() + 180);

  const maxFutureDate = new Date();
  maxFutureDate.setDate(new Date().getDate() + 180);

  const currentDay = new Date();

  const [requestedShifts, setRequestedShifts] = useState<IAvailableShift[]>([]);
  const [isRequestShiftsOverlay, setIsRequestShiftsOverlay] = useState(false);

  const windowHeight = useCurrentHeight();
  const overlayContainerHeight = windowHeight - (windowHeight - 80) * 0.25 + 'px';

  const classes = useStyles({ overlayContainerHeight: overlayContainerHeight });

  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 27.949, lng: -82.75 });
  const [zoom, setZoom] = useState(5);

  const regexZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const isZip = regexZipCode.test(zipCode);

  const geocoder = new google.maps.Geocoder();
  const handleZipSearch = async (zip: string) => {
    geocoder.geocode({ address: zip }, (results, status) => {
      if (status === 'OK' && typeof results !== 'undefined') {
        setCenter({
          lat: results?.[0]?.geometry.location.lat() as number,
          lng: results?.[0]?.geometry.location.lng() as number,
        });
        setZoom(11);
      } else {
        enqueueSnackbar(`This zip code doesn't seem to exist, please try again.`, {
          variant: 'error',
        });
      }
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async position => {
        geocoder.geocode(
          {
            location: { lat: position.coords.latitude, lng: position.coords.longitude },
          },
          (results, status) => {
            if (status === 'OK' && typeof results !== 'undefined') {
              const zip_code =
                results?.[0]?.address_components?.find((component: any) =>
                  component?.types?.includes('postal_code')
                )?.long_name ?? '';

              if (zip_code) {
                setZipCode(zip_code ?? '');
                handleZipSearch(zip_code);
              }
            }
          }
        );
      },
      error => {
        console.log('error', error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocationId === null) setIsOverlayCovering(false);
  }, [selectedLocationId]);

  const displayShifts = async (locationId: number) => {
    setIsOverlayCovering(true);
    try {
      setIsLoadingAvailableShifts(true);
      // These will always have values at this point
      const res = await getAvailableDVMShifts(
        locationId,
        selectedDateRange.startDate as Date,
        selectedDateRange.endDate as Date
      );
      setAvailableShifts(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVM Statuses, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoadingAvailableShifts(false);
    }
  };
  const fetchMapLocations = async () => {
    try {
      setIsLoading(true);
      const res = await getMapLocations(selectedDateRange.startDate, selectedDateRange.endDate);
      setMapLocations(Array.isArray(res) ? res : null);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading map locations, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // set default date range on load
  useEffect(() => {
    if (!selectedDateRange.startDate && !selectedDateRange.endDate) {
      const defaultDateRange =
        format(defaultStartDate, 'M/d/yyyy') + ' - ' + format(defaultEndDate, 'M/d/yyyy');

      setSelectedDateRange({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        key: defaultDateRange,
        inputValue: defaultDateRange,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      fetchMapLocations();
      setInfoWindow(null);
      setIsOverlayCovering(false);
      setIsRequestShiftsOverlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const canUserBid = mapLocations?.find(
    location => location?.locationId === selectedLocationId
  )?.authorizedToWork;

  return (
    <Page customPageHeader={true} title={'Home'}>
      <Box width="100%">
        {!isRequestShiftsOverlay ? (
          <Stack spacing={1}>
            <Grid alignItems={'center'} container spacing={1}>
              <Grid item xs={8} sm={6} md={4} lg={3}>
                <DateRangePicker
                  id="filterDateRange"
                  label="Date Range"
                  value={selectedDateRange}
                  isDisabled={isLoading}
                  onChange={value => {
                    setSelectedDateRange(value);
                  }}
                  fullWidth
                  inputSize="small"
                  minDate={currentDay}
                  maxDate={maxFutureDate}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  autoComplete="nope"
                  label="Zip Code"
                  name="zipCode"
                  value={zipCode}
                  size="small"
                  InputProps={{
                    classes: { root: classes.base },
                    endAdornment: (
                      <IconButton
                        onClick={() => handleZipSearch(zipCode)}
                        className={classes.iconButton}
                        size="small"
                        component={'span'}
                        disabled={!isZip}
                      >
                        <Search />
                      </IconButton>
                    ),
                  }}
                  onChange={e => {
                    // only numbers
                    const re = /^[0-9\b]+$/;
                    // const re = /^\d{5}$)|^\d{9}$|^\d{5}-\d{4}$/;
                    // if value is not blank, then test the regex
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setZipCode(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        ) : null}

        {!isLoading && mapLocations && (
          <div className={classes.overlayContainer}>
            <Map
              locations={mapLocations}
              isMapLoading={isMapLoading}
              isMapLoaded={() => setIsMapLoading(false)}
              infoWindow={infoWindow ?? null}
              setInfoWindow={setInfoWindow}
              showOverlay={locationId => {
                setSelectedLocationId(locationId);
                displayShifts(locationId);
              }}
              handleInfoWindowClose={() => setIsOverlayCovering(false)}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />

            <ShiftOverlay
              isLoading={isLoading}
              fetchMapLocations={fetchMapLocations}
              isLoadingAvailableShifts={isLoadingAvailableShifts}
              availableShifts={availableShifts}
              requestedShifts={requestedShifts}
              setRequestedShifts={setRequestedShifts}
              mapLocations={mapLocations}
              selectedDateRange={selectedDateRange}
              setIsRequestShiftsOverlay={setIsRequestShiftsOverlay}
              isOverlayCovering={isOverlayCovering}
              setIsOverlayCovering={setIsOverlayCovering}
              setSelectedLocationId={setSelectedLocationId}
              selectedLocationId={selectedLocationId}
              overlayContainerHeight={overlayContainerHeight}
            />
            {!isLoadingAvailableShifts &&
              requestedShifts.length > 0 &&
              isDVM &&
              !isDVMDeactivated &&
              !isRequestShiftsOverlay && (
                <Button
                  className={classes.requestShifts}
                  onClick={() => setIsRequestShiftsOverlay(true)}
                  startIcon={<EventAvailable />}
                >
                  {`Request Shifts (${requestedShifts.length})`}
                </Button>
              )}
            {!isLoadingAvailableShifts && isOverlayCovering && isDVMDeactivated && (
              <Button className={classes.nonDvmButton}>
                {`DVM user is deactivated! Can't request shifts.`}
              </Button>
            )}
            {!isLoadingAvailableShifts && isOverlayCovering && !isDVM && (
              <Button className={classes.nonDvmButton}>
                {`Non-DVM user! Can't request shifts.`}
              </Button>
            )}

            {!isLoadingAvailableShifts && isOverlayCovering && isDVM && !canUserBid && (
              <Button href={'mailto: relief@vetcher.com'} className={classes.nonDvmButton}>
                {`You are not on file as being authorized to work in this state. Please contact relief@vetcher.com for assistance.`}
              </Button>
            )}

            <RequestShifts
              isRequestShiftsOverlay={isRequestShiftsOverlay}
              setIsRequestShiftsOverlay={setIsRequestShiftsOverlay}
              requestedShifts={[...requestedShifts]
                .sort((a, b) => {
                  return a.locationId - b.locationId;
                })
                .sort((a, b) => {
                  // @ts-ignore
                  //https://stackoverflow.com/questions/10123953/how-to-sort-an-object-array-by-date-property
                  return new Date(a.shiftDate) - new Date(b.shiftDate);
                })}
              setRequestedShifts={setRequestedShifts}
              setInfoWindow={setInfoWindow}
              setIsOverlayCovering={setIsOverlayCovering}
              overlayContainerHeight={overlayContainerHeight}
              setMapLocations={setMapLocations}
              selectedDateRange={selectedDateRange}
            />
          </div>
        )}
      </Box>
    </Page>
  );
};

const useStyles = makeStyles<Theme, { overlayContainerHeight: string }>((theme: Theme) => ({
  overlayContainer: {
    width: '100%',
    height: ({ overlayContainerHeight }) => overlayContainerHeight,
    position: 'relative',
  },
  base: { padding: 0 },
  overlay: {
    backgroundColor: '#f6f6f6',
    position: 'absolute',
    height: 0,
    width: '100%',
    bottom: 0,
    left: 0,
    visibility: 'hidden',
    transition: '0.3s',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  cover: {
    visibility: 'visible',
    height: '60%',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  requestShiftsCover: {
    visibility: 'visible',
    height: '100%',
    width: '100%',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.default,
  },
  shiftContainer: {
    display: 'flex',
    flexDirection: 'column',
    xOverflow: 'scroll',
    padding: '.5rem 1rem',
  },
  requestShifts: {
    width: '100%',
    borderRadius: 0,
    padding: theme.spacing(1.5),
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      bottom: '-' + theme.spacing(5),
    },
  },
  nonDvmButton: {
    width: '100%',
    borderRadius: 0,
    padding: theme.spacing(1.5),
    position: 'absolute',
    bottom: 0,
    cursor: 'not-allowed',
    [theme.breakpoints.up('md')]: {
      bottom: '-' + theme.spacing(5),
    },
  },
  iconButton: {
    paddingRight: 5,
    '@media (min-width: 400px)': {
      paddingRight: 14,
    },
  },

  date: { padding: '0 .25rem 0 0' },
  shift: { display: 'flex', justifyContent: 'space-between' },
}));
