import { Button, IconButton, Link, Typography, alpha } from '@mui/material';
import { FC } from 'react';
import { Coin } from '../icons/coin';
import { HighlightOff, AddCircle } from '@mui/icons-material';
import clsx from 'clsx';
import {
  IMarketCalendarLocation,
  IShift,
  IMarketCalendarShiftRequest,
} from '../../models/schedule';
import { RSSCheckbox } from './rss-checkbox';
import { useLocation } from 'react-router-dom';
import { IDropdownResponse } from '../../models/util';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { CalendarEventMenu } from './calendar-event-menu';

interface ICalendarEvent {
  isSameDay: (a: Date, b: Date) => boolean;
  isSelected: (a: Date, b: Date) => string;
  isMarket: boolean;
  determineClass: (calendarItem: IShift) => string;
  isBeforeOpenDate: boolean;
  isAfterCloseDate: boolean;
  onCalendar: IShift | undefined;
  location: string | IMarketCalendarLocation;
  day: Date;
  handleModalOpen: (dvmId: number, mondayDate: Date) => Promise<void>;
  setRSSModalSelectedShift: (shift: IShift) => void;
  setRssAddLocation: (location: IMarketCalendarLocation) => void;
  setRssAddDate: (date: Date) => void;
  setIsRSSAddModalOpen: (isOpen: boolean) => void;
  setRssIncentive: (incentive: number) => void;
  cloneDay: Date;
  selectedDate: Date;
  startDate: Date;
  isRelief: boolean;
  allowDvmCardDelete?: boolean;
  selectedDvm: IDropdownResponse | null | undefined;
  selectedDVMSchedule: string[] | undefined;
  handleRemoveClick: (day: Date, locationId?: string | number) => void;
  onDateClickHandle: (date: Date) => void;
  handleApprove: ((request: IMarketCalendarShiftRequest) => void) | undefined;
  handleReject: ((request: IMarketCalendarShiftRequest) => void) | undefined;
  handleReinstate: ((request: IMarketCalendarShiftRequest) => void) | undefined;
  checkedRequests: IMarketCalendarShiftRequest[] | undefined;
  reinstatedRequests: IMarketCalendarShiftRequest[] | undefined;
  rejectedRequests: IMarketCalendarShiftRequest[] | undefined;
  onSchedule: IMarketCalendarLocation[] | undefined;
  thisDaysRequests: IMarketCalendarShiftRequest[] | undefined;
  thisDaysIncentive: number;
  fetchSchedule: (() => Promise<void>) | undefined;
  dateHasRecentCancelation?: boolean;
  showInactiveRequests?: boolean;
}

export const CalendarEvent: FC<ICalendarEvent> = ({
  isSameDay,
  isSelected,
  isMarket,
  determineClass,
  isBeforeOpenDate,
  isAfterCloseDate,
  onCalendar,
  location,
  day,
  handleModalOpen,
  setRSSModalSelectedShift,
  setRssAddLocation,
  setRssAddDate,
  setIsRSSAddModalOpen,
  setRssIncentive,
  cloneDay,
  selectedDate,
  startDate,
  isRelief,
  allowDvmCardDelete,
  selectedDvm,
  selectedDVMSchedule,
  handleRemoveClick,
  onDateClickHandle,
  handleApprove,
  handleReject,
  handleReinstate,
  checkedRequests,
  reinstatedRequests,
  rejectedRequests,
  onSchedule,
  thisDaysRequests,
  thisDaysIncentive,
  fetchSchedule,
  dateHasRecentCancelation,
  showInactiveRequests,
}) => {
  const classes = useStyles();
  const currentRoute = useLocation();

  const menuItems: { action: () => void; label: string; disabled: boolean }[] = [
    {
      action: () => {
        if (onCalendar) {
          handleRemoveClick(cloneDay, (location as IMarketCalendarLocation).locationId);
        }
      },
      label: 'Cancel',
      disabled: false,
    },
  ];

  return (
    <div
      className={clsx(
        `col cell ${isSameDay(day, new Date()) ? 'today' : isSelected(day, selectedDate)}`,
        !isMarket ? classes.dayContainer : classes.bidContainer,
        dateHasRecentCancelation ? classes.recentCancelation : null
      )}
      key={
        isMarket
          ? `${(location as IMarketCalendarLocation).locationName}_${day.toDateString()}`
          : day.toDateString()
      }
    >
      {isBeforeOpenDate || isAfterCloseDate ? (
        <div className={classes.closed}>
          <Typography>Closed</Typography>
        </div>
      ) : null}

      {!isBeforeOpenDate && !isAfterCloseDate && !!onCalendar ? (
        <div className={clsx(classes.bid, determineClass(onCalendar))}>
          <div className={classes.eventMenuContainer}>
            <CalendarEventMenu darkBackground={true} menuItems={menuItems} />
          </div>
          <Typography
            onClick={() => {
              handleModalOpen(onCalendar.dvmId as number, startDate);
            }}
            className={classes.scheduledName}
          >
            {onCalendar.dvmName}
          </Typography>
          {onCalendar.dvmType === 'Contractor' && isMarket && (
            <Link
              className={classes.link}
              color={'inherit'}
              onClick={() => {
                setRSSModalSelectedShift(onCalendar);
                setRssAddLocation(location as IMarketCalendarLocation);
                setRssAddDate(cloneDay);
              }}
            >
              <Typography>
                ${onCalendar.totalAmount}
                {onCalendar.hasIncentive ? <Coin maxHeight={20} /> : null}
              </Typography>
            </Link>
          )}
          {((currentRoute.pathname === '/ft-scheduling' && !isRelief) || allowDvmCardDelete) && (
            <IconButton
              className={classes.removeButton}
              onClick={() => {
                handleRemoveClick(cloneDay, (location as IMarketCalendarLocation).locationId);
              }}
            >
              <HighlightOff />
            </IconButton>
          )}
        </div>
      ) : null}
      {!isMarket && !onCalendar && !isBeforeOpenDate && !isAfterCloseDate ? (
        <Button
          disabled={
            (!selectedDvm && !isMarket) ||
            selectedDVMSchedule
              ?.map(date => new Date(date).getTime())
              ?.includes(new Date(cloneDay).getTime())
          }
          startIcon={<AddCircle />}
          onClick={() => {
            onDateClickHandle(cloneDay);
          }}
        >
          ADD
        </Button>
      ) : null}
      {isMarket &&
        handleApprove &&
        handleReject &&
        checkedRequests &&
        onSchedule &&
        !isBeforeOpenDate &&
        !isAfterCloseDate &&
        ((thisDaysRequests && thisDaysRequests?.length > 0) || onCalendar ? (
          <>
            {thisDaysRequests?.map(request => (
              <RSSCheckbox
                key={`${request.dvmId} ${request.locationId} ${request.shiftDate}`}
                checkedRequests={checkedRequests}
                rejectedRequests={rejectedRequests ?? []}
                request={request}
                onSchedule={
                  Array.isArray(onSchedule) ? onSchedule.flatMap(schedule => schedule.shifts) : []
                }
                handleApprove={handleApprove}
                handleReinstate={handleReinstate}
                handleReject={handleReject}
                location={location as IMarketCalendarLocation}
                handleModalOpen={() => handleModalOpen(request.dvmId, startDate)}
                fetchSchedule={fetchSchedule}
                thisDaysIncentive={thisDaysIncentive}
                reinstatedRequests={reinstatedRequests}
              />
            ))}
            {(showInactiveRequests || !onCalendar) && (
              <IconButton
                disabled={false}
                className={classes.addButtonWithRequests}
                color={'primary'}
                onClick={() => {
                  setRssAddDate(cloneDay);
                  setRssAddLocation(location as IMarketCalendarLocation);
                  setIsRSSAddModalOpen(true);
                  setRssIncentive(thisDaysIncentive);
                }}
              >
                <AddCircle />
              </IconButton>
            )}
          </>
        ) : !isBeforeOpenDate && !isAfterCloseDate ? (
          <Button
            disabled={false}
            startIcon={<AddCircle />}
            onClick={() => {
              setRssAddDate(cloneDay);
              setRssAddLocation(location as IMarketCalendarLocation);
              setIsRSSAddModalOpen(true);
              setRssIncentive(thisDaysIncentive);
            }}
          >
            ADD
          </Button>
        ) : null)}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  locationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    color: alpha(theme.palette.text.primary, 0.875),
    padding: '.5rem',
  },
  dayContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  penciledIn: {
    borderTop: `3px solid ${theme.palette.secondary.light}`,
    borderRight: `3px solid ${theme.palette.secondary.light} !important`,
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
  },
  penciledInRelief: {
    borderTop: `3px solid ${theme.palette.primary.light}`,
    borderRight: `3px solid ${theme.palette.primary.light} !important`,
    borderBottom: `3px solid ${theme.palette.primary.light}`,
    borderLeft: `3px solid ${theme.palette.primary.light}`,
  },
  closed: {
    height: '80%',
    width: '80%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),

    borderTop: `3px solid ${theme.palette.grey[300]}`,
    borderRight: `3px solid ${theme.palette.grey[300]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(1, 0.25, 0),
    alignSelf: 'flex-start',
  },
  addButtonWithRequests: {
    opacity: '0',

    transition: 'opacity .25s ease-out',
    position: 'absolute',
    bottom: -7,
    right: -7,
  },
  bidContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    transition: 'opacity .15s ease-in',

    '&:hover > $addButtonWithRequests': {
      opacity: '1',
    },
  },
  recentCancelation: {
    borderTop: `3px solid ${theme.palette.error.main}`,
    borderRight: `3px solid ${theme.palette.error.main} !important`,
    borderBottom: `3px solid ${theme.palette.error.main}`,
    borderLeft: `3px solid ${theme.palette.error.main}`,
    borderRadius: '5px',
  },
  bid: {
    width: '80%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: '7rem',
    borderTop: `3px solid ${theme.palette.grey[300]}`,
    borderRight: `3px solid ${theme.palette.grey[300]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(1, 0.25, 0),
    alignSelf: 'flex-start',
    '&:last-of-type': {
      marginBottom: theme.spacing(1),
    },
    '&.reinstated': {
      borderTop: `3px solid ${theme.palette.primary.main}`,
      borderRight: `3px solid ${theme.palette.primary.main} !important`,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    position: 'relative',
  },
  winningBid: {
    height: '100%',
    width: '80%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    minHeight: '7rem',
    borderTop: `3px solid ${theme.palette.grey[300]}`,
    borderRight: `3px solid ${theme.palette.grey[300]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(1, 0.25, 0),
    alignSelf: 'flex-start',
    '&:last-of-type': {
      marginBottom: theme.spacing(1),
    },
    '&.reinstated': {
      borderTop: `3px solid ${theme.palette.primary.main}`,
      borderRight: `3px solid ${theme.palette.primary.main} !important`,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    position: 'relative',
  },
  scheduled: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    justifyContent: 'space-around',
  },
  scheduledName: {
    textDecoration: 'underline',
  },
  checkDisabled: {
    backgroundColor: theme.palette.secondary.light,
  },
  scheduledRelief: {
    color: theme.palette.common.white,
    backgroundColor: '#4c5076',
    justifyContent: 'space-around',
  },
  headerDate: {
    color: alpha(theme.palette.text.primary, 0.875),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
  },
  heading: {
    borderRight: ` 1px solid #eee`,
  },
  removeButton: { position: 'absolute', top: 0, left: 0 },
  '.calendar': {
    '& .body': {
      '& .cell': {
        '& .remove': {},
      },
    },
  },
  noBids: {
    alignSelf: 'center',
  },
  link: {
    color: theme.palette.common.white,
  },
  eventMenuContainer: {
    color: 'white',
    position: 'absolute',
    right: '0',
    top: '0',
  },
}));
