import { Component, ErrorInfo, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { logError } from '../../services';

interface Props extends RouteComponentProps {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to app insights.
    logError(error, 'React Render Error');
    // This should provide the component stack trace for the above error.
    logError(errorInfo, 'React Render Error Info');
    this.setState({ error: error.message });
  }

  public render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      this.props.history.push('/error', { error: this.state.error });
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
